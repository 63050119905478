<template>
  <div class="pageTtlBlock--min">
    <p class="inner">
      <span>{{ ttlEn }}</span>{{ ttlJp }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    ttlEn: String,
    ttlJp: String
  }
}
</script>
<style lang="scss" scoped>
.pageTtlBlock--min {
  background: #f2f0ec;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 0.06em;
  .inner {
    padding: 40px 0;
  }
  span {
    font-family: $Lato;
    font-size: 2.8rem;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .pageTtlBlock--min{
    font-size: 1rem;
    .inner{
      padding: 20px 0;
    }
    span{
      font-size: 1.9rem;
    }
  }
}
</style>
